import React from 'react'
import homepagePhoto from '../images/work/01_PB_MATADOR/PHOTOS/MA_01.jpg'

import Layout from '../components/layout'

import './style.css'

const IndexPage = () => (
  <Layout>
    <img className="home-page" src={homepagePhoto} alt="Constructed House Example"></img>
  </Layout>
)

export default IndexPage
